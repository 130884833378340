/*
 * @Date: 2024-11-07 20:45:52
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-08 14:53:07
 * @Description:
 */
// 新接口 ecommerce
// 接口路径：http://8.130.13.119:9005
// 接口文档：http://8.130.13.119:9005/docs.html
import qs from "qs"; // 序列化
export default function (axios) {
  return {
    //--------------------------------------------------------------------设置：职位职级
    // 职位-增、改
    savePosiApi(params) {
      let url = "/internal/staffArchiveCtl/insertStaffPosition"; //新增
      if (params.id) {
        url = "/internal/staffArchiveCtl/editStaffPosition"; //修改
      }
      return axios.post(url, params);
    },
    // 职位-删
    delPosiApi(params) {
      let url = "/internal/staffArchiveCtl/delStaffPosition";
      return axios.get(url, { params });
    },
    // 职位-查
    getPosiApi(params) {
      let url = "/internal/staffArchiveCtl/findStaffPositionNose";
      return axios.get(url, { params });
    },
    // 职级-增、改
    savePostApi(params) {
      let url = "/internal/staffArchiveCtl/insertStaffRank"; //新增
      if (params.id) {
        url = "/internal/staffArchiveCtl/editStaffRank"; //修改
      }
      return axios.post(url, params);
    },
    // 职级-删
    delPostApi(params) {
      let url = "/internal/staffArchiveCtl/delStaffRank";
      return axios.get(url, { params });
    },
    // 职级-查
    getPostApi(params) {
      let url = "/internal/staffArchiveCtl/findStaffRankNose";
      return axios.get(url, { params });
    },
  };
}
