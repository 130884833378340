/*
 * @Description: 好招的 token
 * @Date: 2023-11-13 10:07:18
 * @LastEditTime: 2024-01-10 17:04:15
 */
export default {
  namespaced: true,
  local: true,
  state: {
    access_token: "",
    recruit_token: "", // // 企业登录的时候 token / none  员工登录的时候 token / noneComp 这种情况是企业没在好招注册 noneStaff  /  这种情况是员工没在好招注册
    recruit_vipId: "",
  },
  mutations: {
    setRecruitToken(state, data) {
      console.log(data, 'data')
      state.recruit_token = data.access_token;
      state.recruit_vipId = data.vipId || "";
      if (data.access_token) {
        state.access_token = data.access_token;
      } else {
        state.access_token = "";
      }
    },
  },
  actions: {
    setRecruitToken({ commit, dispatch }, data) {
      commit("setRecruitToken", data);
      if (data.code) {
        dispatch("exam/setToken", data, { root: true });
        dispatch("exam/getUserInfo", data, { root: true });
      }
    },
  },
};
