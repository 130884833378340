
import store from "@/store";

function getUrl() {
  // return store.state.user.systemSign == 1 ? "/hrm/outsource" : "/hro/customer";
  return store.state.user.systemSign == 1 ? "/outsource" : "/customer";
}
function getParams(query, page) {
  let params = {
    pageNum: page.current,
    pageSize: page.size,
  };
  for (let k of Object.keys(query)) {
    if (query[k]) {
      params[k] = query[k];
    }
  }
  return params;
}

export default function (axios) {
  return {
    // 校验token是否过期
    getCheckToken() {
      return axios.get("/userCtl/checkTokenNose");
    },

    // 查询所有的未读消息数量
    selectNoReadNewsCount(params) {
      return axios.get('/cNewsRemind/selectNoReadNewsCount', {
        params,
      });
    },
    // 查询指定系统消息列表
    selectNewsRemindByType(params, signal) {
      return axios.get('/cNewsRemind/selectNewsRemindByType', {
        params,
        signal,
      });
    },


    // 统计
    staffSelectCount(params, sign) {
      let url = "/staffArchiveCtl/selectCount";
      url = "/" + (sign === "hro" ? axios.urlHro : axios.urlHrm) + url;
      return axios.post(url, params);
    },


    // 获取签名
    getStaffcloudIMUserSig(params) {
      return axios.get("/userSig/getUserSig", { params });
    },


    // 供应商/客户列表, /outsource/selectPage    /customer/selectPage
    providerNoseList(params, sysType, nose) {
      let prefix = sysType == 1 ? "outsource" : "customer";
      let url = `/${prefix}/selectPage`;
      // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
      url = url + (nose ? "Nose" : "");
      return axios.post(url, {}, { params });
    },
    // 供应商列表,
    providerList(query, page, nose) {
      let params = getParams(query, page);
      let url = `${getUrl()}/selectPage`;
      // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
      url = url + (nose ? "Nose" : "");
      return axios.post(url, {}, { params });
    },
    // 供应商列表(简化版,nose传值“Nose”，注意大小写)
    providerListApi(params, nose) {
      nose = nose ? nose : ""
      let url = `${getUrl()}/selectPage${nose}`;
      return axios.post(url, {}, { params });
    },


  };
}
