import requestProject from "@/assets/plugins/asktrue/request-project"; // project 实例
import requestUser from "@/assets/plugins/asktrue/request-user"; // user 实例
import requestEcommerce from "@/assets/plugins/asktrue/request-ecommerce"; // user 实例

import qs from "qs"; // 序列化

// // 	网站首页项目查询分页
// export const selectAllProject = data => getReq(apiUrl3+'/anon/project/selectAllProject' ,data)
export function selectAllProject(data) {
  return requestEcommerce({
    url: "/goodsSet/selectAllProjectNose",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// // 个人账户查询买的项目
// export const selectPersonProject = data => getReq(apiUrl3+'/personProjectCtl/selectPersonBuyProject' ,data)

// // 项目标签
// export const selectProjectLabelListPage = data => getReq(apiUrl1+"/anon/selectProjectLabelListPage",data)
export function selectProjectLabelListPage(params) {
  return requestUser({
    url: "/anon/selectProjectLabelListPage",
    method: "get",
    params,
  });
}

// export const selectParams = data => getReq(apiUrl1+"/parameter/getDictNose",data)
export function selectParams(params) {
  console.log('555555555555555555555555555555');
  return requestUser({
    url: "/parameter/getDictNose",
    method: "get",
    params,
  });
}

// export const selectParams2 = data => getReq(apiUrl1+"/parameter/getDictNose",data)

// //查询网站首页项目详情
// export const getProDetailReq = data => getReq(apiUrl3+'/anon/project/selectProjectById' ,data)
export function getProDetailReq(params) {
  return requestProject({
    url: "/anon/project/selectProjectById",
    method: "get",
    params,
  });
}

// //项目评论分页查询
// export const getProEvaReq = data => getReq(apiUrl3+'/project/selectCommentByProjectIdPageNose' ,data)
export function getProEvaReq(params) {
  return requestProject({
    url: "/project/selectCommentByProjectIdPageNose",
    method: "get",
    params,
  });
}

// //新增评论
// export const addProEvaReq = data => postReq(apiUrl3+'/project/insertCommentToBuyer' ,data)
export function addProEvaReq(data) {
  return requestProject({
    url: "/project/insertCommentToBuyer",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 新增评论回复
// export const addProEveReplyReq = data => postReq(apiUrl3+'/project/updateCommentToReply' ,data)
export function addProEveReplyReq(data) {
  return requestProject({
    url: "/project/updateCommentToReply",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// //收藏项目
// export const collectProReq = data => postReq(apiUrl3+'/project/collectProject' ,data)
export function collectProReq(data) {
  return requestProject({
    url: "/project/collectProject",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
