import axios from "axios";
import store from "@/store";
import Vue from "vue";
// create an axios instance
const service = axios.create({
  baseURL: "https://asktrue.cn/ecommerce", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    // let each request carry token
    // ['access_token'] is a custom headers key
    // please modify it according to the actual situation
    let access_token = "";
    // if (
    //   window.location.href.indexOf("/recruit") > -1 ||
    //   window.location.href.indexOf("matcheasy") > -1
    // ) {
    //   // 招聘栏目下所有的asktrue请求
    //   access_token = store.state.matcheasy_asktrue.access_token;
    // } else if (window.location.href.indexOf("/Project") > -1) {
    //   // todo 区分好招和云掌门进入阿仕图页面的阿仕图token
    //   access_token = store.state.access_token1;
    // } else {
    //   access_token = store.state.asktrue.access_token;
    // }
    access_token = store.state.user.access_token;
    config.headers["access_token"] = access_token;
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== "200") {
      // 没有token或者token过期自动跳转到登录界面
      if (res.code == 405 || res.code == 406) {
        store.dispatch("user/setAccess_token", "").then(() => {
          location.reload();
        });
      }
      // return Promise.reject(new Error(res.message || "Error"));
      Vue.prototype.$message.error(res.msg || "接口报错" + res.code);
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
