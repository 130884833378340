/*
 * @Date: 2024-01-11 15:42:38
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-06 13:42:37
 * @Description:
 */
// export const apiUrl1 = "https://user.asktrue.co";
// export const apiUrl2 = "https://paper.asktrue.co";
// export const apiUrl3 = "https://project.asktrue.co";
// export const apiUrl4 = "https://answer.asktrue.co";
// export const apiUrl5 = "https://score.asktrue.co";
// export const apiUrl6 = "https://project.asktrue.co";
// export const apiUrl7 = "https://answerdata.asktrue.co";

export const apiUrl1 = "https://asktrue.cn/user"; //user  //39.105.207.150:8085

export const apiUrl11 = "https://asktrue.cn/user"; //新user  http://8.130.13.119:9005
export const apiUrl12 = "https://asktrue.cn/ecommerce"; //http://8.130.13.119:9006

export const apiUrl2 = "https://asktrue.cn/paper"; //paper 8086
export const apiUrl3 = "https://asktrue.cn/project"; //project 8087
export const apiUrl4 = "https://asktrue.cn/answer"; //answer  8088
export const apiUrl5 = "https://asktrue.cn/score"; //更新成绩 8089
export const apiUrl6 = "https://asktrue.cn/project"; //project监考 8087
export const apiUrl7 = "https://asktrue.cn/answerdata"; //同步成绩  8089
