// 部门相关接口
export default function (axios) {
  return {
    // // 新增部门
    // addDep(name, parentId, dynamicId) {
    //   return axios.post("/cCompDepartment/addDept", {
    //     name,
    //     parentId,
    //     dynamicId,
    //   });
    // },
    // // 获取部门数据
    // getDep(params) {
    //   return axios.get("/cCompDepartment/selectDeptByCompId", { params });
    // },
    // // 重命名部门
    // updateDep(id, name, dynamicId) {
    //   return axios.post(
    //     "/cCompDepartment/updateDept",
    //     {},
    //     { params: { id, name, dynamicId } }
    //   );
    // },
    // // 删除部门
    // removeDep(id, dynamicId) {
    //   return axios.post(
    //     "/cCompDepartment/updateDept",
    //     {},
    //     { params: { id, status: 2, dynamicId } }
    //   );
    // },
  };
}
