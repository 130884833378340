/*
 * @Date: 2024-11-07 20:45:52
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-08 17:55:34
 * @Description:
 */
// 新接口 ecommerce
// 接口路径：http://8.130.13.119:9005
// 接口文档：http://8.130.13.119:9005/docs.html
import qs from "qs"; // 序列化
export default function (axios) {
  return {
    // 登录接口
    login(params) {
      return axios.post("/anon/generateToken", params, {
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },
    // 退出登录接口
    logout() {
      return axios.get("/invalidToken");
    },
    // 获取可切换的身份列表(云掌门切换身份)
    getUserList() {
      return axios.get("/changeUserList?systemId=2");
    },
    // 切换用户身份
    changeUser(params) {
      return axios.get("/changeUser", { params });
    },
    // 获取用户信息
    getUserInfo() {
      return axios.get("/companyMemberCtl/selectUserInfoByToken");
    },
    // 获取验证码
    getCode(userLoginCode, type) {
      return axios.get("/anon/verifyCode", {
        params: { userLoginCode, verifyType: type ? type : 2 },
      });
    },
    // 获取菜单栏
    getMenu(compId, userId) {
      return axios.get("/cAccountAuthCtl/selectAuthByUserId", {
        params: { compId, userId, systemId: 2 },
      });
    },
    // 获取员工权限
    getStaffAuth(compId, childAccountId) {
      return axios.get("/cPowerSetCtl/selectPower", {
        params: { compId, childAccountId, systemId: 2 },
      });
      s;
    },
    // 修改员工权限
    changeStaffAuth(params) {
      params.systemId = 2;
      return axios.post("/cPowerSetCtl/updatePower", params);
    },
    // 获取员工列表
    getStaff(par, controller) {
      par.systemId = 2;
      let signal =
        controller && controller.signal ? { signal: controller.signal } : {};
      let params = { ...par, ...signal };
      return axios.get("/companyStaffMemberCtl/selectCompStaffMemberPage", {
        params,
      });
    },
    // 常用菜单栏变更
    updateMenu(params) {
      return axios.post("/cAccountAuthCtl/updateDefaultAuth", params);
    },
    // 设为管理员
    setAdmin(params) {
      params.systemId = 2;
      let url = "/companyStaffMemberCtl/setStaffAdmin"; //新增
      return axios.get(url, { params });
    },
  };
}
