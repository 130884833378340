
export default function (axios) {
    return {
      // 获取会员信息
      getMemberInfo(params) {
        return axios.get("/companyStaffMemberCtl/selectCompStffMember", { params });
      },
      // 修改会员信息
      changeMemberInfo(info) {
        return axios.post("/companyStaffMemberCtl/updateCompStaff", info);
      },
      // 安全页面修改员工密码
      changePsdStaffReq(info) {
        return axios.post(
          "/iUser/updatePassWord",
          {},
          { params: info }
        );
      },
      // 绑定企业子账号邮箱
      bindChildEmail(info) {
        return axios.post("/cChildUser/bindEmail", {}, { params: info });
      },
      // 绑定企业子账号手机号
      bindChildMobile(info) {
        return axios.post(
          "/cChildUser/confirmMobileCode",
          {},
          { params: info }
        );
      },
    };
  }
  