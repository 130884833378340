export default function (axios) {
  return {
    // //查询短信 邮件 数量
    // export const getCountReq = data => getReq(apiUrl+"/sendNoticeCtl/selectMsgAndMailCount",data)
    getCountReq(params) {
      return axios.get("/sendNoticeCtl/selectMsgAndMailCount", { params });
    },
    // //查询短信 邮件订单详情
    // export const getOrderInfoReq = data => getReq(apiUrl+"/sendNoticeCtl/selectRechargeOrderInfo",data)

    // //查询短信 邮件订单分页
    // export const getSmsListReq = data => getReq(apiUrl+"/sendNoticeCtl/selectSMSOrMailPage",data)
    getSmsListReq(params) {
      return axios.get("/sendNoticeCtl/selectSMSOrMailPage", { params });
    },
    // //查询订单分页
    // export const getOrderListReq = data => getReq(apiUrl+"/orderCtl/selectOrderPageByParam",data)

    // //查询账户分页
    // export const selectAccountPage = data => getReq(apiUrl+"/accountCtl/selectAccountPage",data)
    // //查询企业用户余额
    // export const selectCompOrStaffAcconutInfo = data => getReq(apiUrl+"/cPayAccount/selectAccount",data)
    // selectCompOrStaffAcconutInfo(params) {
    //   return axios.get("/cPayAccount/selectAccount", { params });
    // },
    // // //充值弹窗企业用户账户余额
    // // export const rechargeComAccount = data => postReq(apiUrl+"/cOrderCtl/updateEmailCountAndSMSCountAndBalance",data)
    // //公司或者员工会员登录 -》 账户列表查询
    // export const selectAccountList = data => getReq(apiUrl+"/cPayAccount/selectAccountPage",data)
    // // 账户查询(余额，交易数量，邮件数量，短信数量)
    getCompOrStaffAcconutInfo(params) {
      return axios.get("/cPayAccount/selectCompOrStaffAcconutInfo", {
        params,
      });
    },

    // // 查询产品分类字段
    // export const accountGetGoodName = data => getReq(apiUrl+"/accountCtl/getGoodName",data)

    /*员工相关*/
    //查询企业员工分页
    // export const getStaffListPageReq = data => getReq(apiUrl+"/compStffMember/selectCompStaffMemberPage",data)
    getStaffListPageReq(par) {
      if (par.system) {
        delete par.system;
      }
      let params = {
        ...par,
        systemId: 4,
      };
      return axios.get("/companyStaffMemberCtl/selectCompStaffMemberPage", {
        params,
      });
    },

    // //查询企业员工
    // // export const getStaffListReq = data => getReq(apiUrl+"/compStffMember/selectCompStffMember",data)
    // getStaffListReq(params) {
    //   return axios.get("/companyStaffMemberCtl/selectCompStffMember", {
    //     params,
    //   });
    // },
    // // //企业员工会员添加
    // // export const addStaffReq = data => postReq(apiUrl+"/compStffMember/insertCompStaffInfo",data,{ContentType:'application/json;charset=UTF-8'})
    // addStaffReq(params) {
    //   return axios.post("/companyStaffMemberCtl/insertCompStaffInfo", params);
    // },
    // // //企业员工会员修改
    // // export const updateStaffReq = data => postReq(apiUrl+"/compStffMember/updateCompStaff",data,{ContentType:'application/json;charset=UTF-8'})
    // updateStaffReq(params) {
    //   return axios.post("/companyStaffMemberCtl/updateCompStaff", params);
    // },
    // //企业员工会员状态修改
    // export const updateCompStaffMemberStatus = data => postReq(apiUrl+"/compStffMember/updateCompStaffMemberStatus",data)
    // updateCompStaffMemberStatus(params) {
    //   return axios.post("/compStffMember/updateCompStaffMemberStatus", params, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   });
    // },
    // /*员工相关*/

    // // 企业查询部门
    // selectDeptByCompId(params) {
    //   params.systemId = 4;
    //   return axios.get("/cCompDepartment/selectDeptByCompId", { params });
    // },
    // // 企业新增部门
    // export const addDeptReq = data => postReq(apiUrl+"/cCompDepartment/addDept",data,{ContentType:'application/json;charset=UTF-8'})
    // addDeptReq(params) {
    //   return axios.post("/cCompDepartment/addDept", params, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // },
    // // 企业更新或删除部门
    // export const updateDeptReq = data => postReq(apiUrl+"/cCompDepartment/updateDept",data)
    // updateDeptReq(params) {
    //   return axios.post("/cCompDepartment/updateDept", params, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    // },
    // /*员工相关*/

    // // 查看用户权限
    // export const selectUserAuth = data => getReq(apiUrl+"/selectAuthByToken",data)
    selectUserAuth(params) {
      return axios.get("/selectAuthByToken", { params });
    },

    // /*发票相关*/
    // // 公司/员工
    // // 查询发票列表/查询单个发票信息
    // export const selectInvoiceList = data => getReq(apiUrl+"/cReceiptInfoCtl/selectReceiptInfo",data)
    // // 新增发票信息
    // export const insertInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/insertReceiptInfo",data)
    // // 修改
    // export const updateInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/updateReceiptInfo",data)
    // // 删除
    // export const deleteInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/updateReceiptStatus",data)
    // // 个人
    // // 查询发票列表/查询单个发票信息
    // export const selectPersonInvoiceList = data => getReq(apiUrl+"/cReceiptInfoCtl/selectReceiptInfoPerson",data)
    // // 新增发票信息
    // export const insertPersonInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/insertReceiptInfoPerson",data)
    // // 修改
    // export const updatePersonInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/updateReceiptInfoPerson",data)
    // // 删除
    // export const deletePersonInvoice = data => getReq(apiUrl+"/cReceiptInfoCtl/updateReceiptStatusPerson",data)

    // //co企业账号头部设置
    // //编辑用户自定义参数（根据有无id判断是否是新增还是修改）
    // export const editCompanyOfficialParamReq = data => postReq(apiUrl+"/userCtl/editCompanyOfficialParam",data,{ContentType:'application/json;charset=UTF-8'})
    editCompanyOfficialParamReq(data) {
      return axios.post("/userCtl/editCompanyOfficialParam", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // //查询当前用户自定义参数(设置)
    // export const selectByCompanyOfficialParamReq = data => getReq(apiUrl+"/userCtl/selectByCompanyOfficialParamNose",data)
    selectByCompanyOfficialParamReq(data) {
      return axios.get("/userCtl/selectByCompanyOfficialParamNose", { data });
    },
    // // 查询体检项目
    // getPhysicalItemsReq(data) {
    // return axios.
    //   getReq(apiUrl + "/parameter/getDictNose?parameterType=tPhysical", data);},
    getPhysicalItemsReq(data) {
      return axios.get("/parameter/getDictNose?parameterType=tPhysical", {
        data,
      });
    },
  };
}
