/*
 * @Date: 2024-11-07 10:42:08
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-12 11:22:13
 * @Description:
 */
/*
 * @Date: 2024-11-07 10:42:08
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-07 10:57:47
 * @Description:
 */
export default function (axios) {
  return {
    selectDeptByCompId(params) {
      params.systemId = 4;
      return axios.get("/cCompDepartment/selectDeptByCompId", { params });
    },
    updateCompStaffMemberStatus(params) {
      return axios.post("/compStffMember/updateCompStaffMemberStatus", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    // 企业新增部门
    addDeptReq(params) {
      params.systemId = 4;
      return axios.post("/cCompDepartment/addDept", params, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // 企业更新或删除部门
    updateDeptReq(params) {
      params.systemId = 4;
      return axios.post("/cCompDepartment/updateDept", params, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    getStaffListReq(params) {
      return axios.get("/companyStaffMemberCtl/selectCompStffMember", {
        params,
      });
    },
    //企业员工会员添加
    addStaffReq(params) {
      params.systemId = 4;
      return axios.post("/companyStaffMemberCtl/insertCompStaffInfo", params);
    },
    //企业员工会员修改
    updateStaffReq(params) {
      params.systemId = 4;
      return axios.post("/companyStaffMemberCtl/updateCompStaff", params);
    },
    getPower(params) {
      params.systemId = params.systemId || 4;
      return axios.get("/cPowerSetCtl/selectPower", { params });
    },
    getLeftPower(params) {
      params.systemId = 4;
      return axios.get("/cAccountAuthCtl/selectAuthByUserId", { params });
    },
    updatePower(params) {
      params.systemId = 4;
      return axios.post("/cPowerSetCtl/updatePower", params);
    },
    selectMenuTree(params) {
      params.systemId = 2;
      return axios.get("/cAccountAuthCtl/selectMenuTree", { params });
    },
  };
}
